module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Frontend developer CV","short_name":"Developer CV","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","prefer_related_applications":true,"icon":"src/images/logo/vovoka-circle-32px.png","icons":[{"src":"src/images/logo/vovoka-path-192px.webp","sizes":"192x192","type":"image/webp","purpose":"any maskable"},{"src":"src/images/logo/vovoka-path-512px.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e6de4ffdefae8fee592e012454957395"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
